import React from 'react';
import {
    Page,
    Row,
    Col,
    BlockTitle,
    Block,
    Link
} from 'framework7-react';
import {Helmet} from "react-helmet";

import './paymentInfoPage.css';
import IwdNavbar from '../../shared/navbar/navbar';
import IwdBottomBar from '../../shared/bottomBar/bottomBar';

import PageBlock from '../../shared/pageBlock/pageBlock';

export default class extends React.Component {
    render() {
        return <Page>
            <Helmet>
                <title>Påmeldinger.no - Ta betalt fra deres deltakere</title>
                <meta name="description" content="Påmeldinger.no bruker Stripe for å levere raske og sikre betalinger."/>
                <meta property="og:title" content="Påmeldinger.no - Betalinger med Stripe"/>
                <meta property="og:image" content={`${process.env.PUBLIC_URL}/img/favicons/android-icon-192x192.png`}/>
                <meta property="og:description" content="Påmeldinger.no bruker Stripe for å levere raske og sikre betalinger."/>
                <meta property="og:type" content="website"/>
            </Helmet>
            <IwdNavbar/>
            <PageBlock width="680px">
                <BlockTitle large>
                Ta betalt fra deres deltakere
                </BlockTitle>
                <Block>
                    <p style={{fontSize:'20px'}}>
                    Påmeldinger.no sammarbeider med <Link href="https://stripe.com/" external target="_blank"><b>Stripe</b></Link> og <Link href="https://www.vipps.no" external target="_blank"><b>Vipps</b></Link> for å levere raske og sikre betalinger.
                    </p>
                    <p style={{fontSize:'20px'}}>
                    Når dere selger billetter gjennom Påmeldinger.no er vi aldri i befatning med pengene. Fra deres deltakere betaler har dere full kontroll på betalingen gjennom deres Stripe-konto eller Vipps-konto. <b>Dere mottar pengene direkte fra Stripe eller Vipps, så Påmeldinger.no har ingen mulighet til å holde tilbake betalinger.</b>
                    </p>
                </Block>
                <br/>
            </PageBlock>
            <PageBlock width="840px" color="var(--bg-light-gray-1)">
                <Row noGap>
                    <Col width="100" medium="60">
                    <h2>Rask og enkel betaling!</h2>
                        <Block style={{marginTop:0}}>
                            <p>
                            Du som arrangør bestemmer hvor mye en deltaker skal betale for å melde seg på dine arrangementer. Du kan bestemme ulik pris for ulike billettyper.
                            </p>
                            <p>
                            Vi sørger for at dine deltakere betaler når de melde seg på. Deltakeren får en bekreftelse på at betalingen er gjennomført, og blir så meldt på arrangementet.
                            </p>
                            <p>
                            Deltakeren betaler med <b>Vipps</b>, eller sitt <b>debet-/kredittkort</b>. Påmeldinger.no aksepterer Visa, Mastercard, American Express og mange flere betalingskort. Så din deltaker kan enkelt betale.
                            </p>
                        </Block>
                    </Col>
                    <Col width="100" medium="40" className="stripe-payment-demo-col">
                    <div className="stripe-payment-demo"/>
                    </Col>
                </Row>
            </PageBlock>
            <PageBlock width="840px" color="var(--f7-theme-color)">
                <Row noGap className="text-color-white">
                    <Col width="100" medium="50">
                    <h2>Sikker betaling</h2>
                        <Block style={{marginTop:0}}>
                            <p>
                            Med Påmeldinger.no kan du være sikker på at betalingsinformasjonen til dine kunder er trygg.  Alle informasjonen for å gjennomføre betalingen sendes kryptert direkte til vår betalingsleverandør.
                            </p>
                            <p>
                            Påmeldinger.no følger alle krav i PSD2 og Strong Customer Authentication. Så du kan være trygg på at betalingene blir gjennomført sikkert.
                            </p>
                        </Block>
                    </Col>
                    <Col width="100" medium="50">
                    <h2>Raske utebetalinger</h2>
                        <Block style={{marginTop:0}}>
                            <p>
                            Vår betalingsleverandører – Stripe og Vipps – er kjent for å ha raske utbetalinger.
                            </p>
                            <p>
                            Stripe vil løpende utbetale pengene fra dine arrangementer 7 kalenderdager fra salget ble gjennomført.
                            </p>
                            <p>
                            Vipps utbetaler oppgjøret 2 dager etter at pengene er sendt fra vippseren.
                            </p>
                        </Block>
                    </Col>
                </Row>
                <Row noGap className="text-color-white">
                    <Col width="100" medium="50">
                    <h2>Full kontroll</h2>
                        <Block style={{marginTop:0}}>
                            <p>
                            Du har full kontroll på alle salgene dine. Gjennom administrasjonssiden til Påmeldinger.no kan du se alle betalinger og salg i sanntid.
                            </p>
                            <p>
                            Du har tilgang til mer detaljert informasjon om betalingen gjennom administrasjonspanelet til Stripe og Vipps.
                            </p>
                        </Block>
                    </Col>
                    <Col width="100" medium="50">
                    <h2>Forskriftsmessig</h2>
                        <Block style={{marginTop:0}}>
                            <p>
                            Påmeldinger.no er aldri i befatning med dine penger, og vi trenger av den grunn ikke konsesjon av Finanstilsynet. Vår betalingsleverandører – Stripe og Vipps – har konsesjon til å levere betalingstjenester i Norge. Stripe og Vipps har også rutiner for å forhindre hvitvasking og terrorfinansiering.
                            </p>
                        </Block>
                    </Col>
                </Row>
            </PageBlock>
            <IwdBottomBar/>
        </Page>
    }
};
