import React, { Suspense, lazy } from 'react';
import {
    Row,
    Col,
    Link,
    Card,
    CardContent,
    CardHeader,
    Icon,
    Popup,
    Page,
    Navbar,
    NavRight,
    Preloader
} from 'framework7-react';
import './helpVideos.css';
//import VideoPlayer from '../../../shared/videoPlayer/videoPlayer';

const VideoPlayer = React.lazy(() => import('../../../shared/videoPlayer/videoPlayer'));

class HelpVideosContainer extends React.Component {
    render() {
      return <Card outline className="video-card-header-pic">
            <CardHeader
                className="no-border"
                valign="bottom"
                style={{backgroundImage:`url(${this.props.img})`}}
            >
                <Icon size={"120px"} material="play_arrow"/>
            </CardHeader>
            <CardContent>
                <p className="title">{this.props.title}</p>
                <p>{this.props.body}</p>
            </CardContent>
        </Card>;
    }
  }


export class HelpVideosAll extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          videoplayer_popup: false
        }
    }

    render() {
      return <>
          <Row noGap>
            <Col onClick={()=>this.setState({videoplayer_popup:true,video_link:"https://storage.googleapis.com/iwd-event.appspot.com/help-videos/v1-new_org.mp4",video_title:"Opprett din bruker og organisasjon"})} width="100" medium="50">
                <HelpVideosContainer img={`${process.env.PUBLIC_URL}/img/help_videos/new_org.jpg`} title="Opprett din bruker og organisasjon" body="Denne videoen viser deg hvordan du oppretter en ny bruker og hvordan du oppretter en organisasjon."/>
            </Col>
            <Col onClick={()=>this.setState({videoplayer_popup:true,video_link:"https://storage.googleapis.com/iwd-event.appspot.com/help-videos/m9-user_register.mp4",video_title:"Slik melder deltakeren seg på"})} width="100" medium="50">
                <HelpVideosContainer img={`${process.env.PUBLIC_URL}/img/help_videos/register.jpg`} title="Slik melder deltakeren seg på" body="Denne videoen viser hvordan dine deltakere kan melde seg på deres arrangementer."/>
            </Col>
            <Col onClick={()=>this.setState({videoplayer_popup:true,video_link:"https://storage.googleapis.com/iwd-event.appspot.com/help-videos/v2-new_event.mp4",video_title:"Opprett et nytt arrangement"})} width="100" medium="50">
                <HelpVideosContainer img={`${process.env.PUBLIC_URL}/img/help_videos/new_event.jpg`} title="Opprett et nytt arrangement" body="Denne videoen viser deg hvordan du oppretter et nytt arrangement."/>
            </Col>
            <Col onClick={()=>this.setState({videoplayer_popup:true,video_link:"https://storage.googleapis.com/iwd-event.appspot.com/help-videos/v4-all_payments.mp4",video_title:"Se alle betalinger"})} width="100" medium="50">
                <HelpVideosContainer img={`${process.env.PUBLIC_URL}/img/help_videos/payments.jpg`} title="Se alle betalinger" body="Denne videoen viser deg hvordan du kan se alle betalinger fra dine deltakere."/>
            </Col>
            <Col onClick={()=>this.setState({videoplayer_popup:true,video_link:"https://storage.googleapis.com/iwd-event.appspot.com/help-videos/v10-checkin.mp4",video_title:"Innslipp og deltakerregistrering"})} width="100" medium="50">
                <HelpVideosContainer img={`${process.env.PUBLIC_URL}/img/help_videos/f4_checkin.jpg`} title="Innslipp og deltakerregistrering" body="Denne videoen viser deg hvordan du registrerer deltakere når de ankommer arrangementet."/>
            </Col>
            <Col onClick={()=>this.setState({videoplayer_popup:true,video_link:"https://storage.googleapis.com/iwd-event.appspot.com/help-videos/v5-privacy.mp4",video_title:"Se alle lagrede personopplysninger"})} width="100" medium="50">
                <HelpVideosContainer img={`${process.env.PUBLIC_URL}/img/help_videos/privacy.jpg`} title="Se alle lagrede personopplysninger" body="Denne videoen viser deg hvordan du kan se og slette personopplysninger fra dine deltakere."/>
            </Col>
            <Col onClick={()=>this.setState({videoplayer_popup:true,video_link:"https://storage.googleapis.com/iwd-event.appspot.com/help-videos/v3_new_payment_event.mp4",video_title:"Ta betalt fra dine deltakere"})} width="100" medium="50">
                <HelpVideosContainer img={`${process.env.PUBLIC_URL}/img/help_videos/charge.jpg`} title="Ta betalt fra dine deltakere" body="Denne videoen viser deg hvordan du tar betalt fra dine deltakere når de melder seg på et arrangement."/>
            </Col>
            <Col onClick={()=>this.setState({videoplayer_popup:true,video_link:"https://storage.googleapis.com/iwd-event.appspot.com/help-videos/v7-payouts.mp4",video_title:"Se alle utbetalinger"})} width="100" medium="50">
                <HelpVideosContainer img={`${process.env.PUBLIC_URL}/img/help_videos/payouts.jpg`} title="Se alle utbetalinger" body="Denne videoen viser deg hvor du kan se alle utbetalingene fra Påmeldinger.no til din organisasjon."/>
            </Col>
            <Col onClick={()=>this.setState({videoplayer_popup:true,video_link:"https://storage.googleapis.com/iwd-event.appspot.com/help-videos/v6-landingpage.mp4",video_title:"Opprett offentlig organisasjonsside"})} width="100" medium="50">
                <HelpVideosContainer img={`${process.env.PUBLIC_URL}/img/help_videos/landingpage.jpg`} title="Opprett offentlig organisasjonsside" body="Denne videoen viser hvordan du oppretter en samle-nettside for alle dine arrangementer."/>
            </Col>
            <Col onClick={()=>this.setState({videoplayer_popup:true,video_link:"https://storage.googleapis.com/iwd-event.appspot.com/help-videos/v8-refund.mp4",video_title:"Refunder en betaling"})} width="100" medium="50">
                <HelpVideosContainer img={`${process.env.PUBLIC_URL}/img/help_videos/refund.jpg`} title="Refunder en betaling" body="Denne videoen viser deg hvordan du refunderer en betaling til en deltaker."/>
            </Col>
          </Row>
          <br/>
          <Popup animate={false} className="videoplayer-popup" opened={this.state.videoplayer_popup} onPopupClosed={() => this.setState({videoplayer_popup : false})}>
            {this.state.videoplayer_popup &&
            <Page themeDark>
                <Navbar title={this.state.video_title}>
                <NavRight>
                    <Link popupClose iconMaterial="close"></Link>
                </NavRight>
                </Navbar>
                <Suspense fallback={<div className="text-align-center" style={{margin:'26px'}}><Preloader></Preloader></div>}>
                    <VideoPlayer video={this.state.video_link}/>
                </Suspense>
            </Page>
            }
          </Popup>
      </>
    }
};

export class HelpVideosSmall extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          videoplayer_popup: false
        }
    }

    render() {
      return <>
          <Row noGap>
            <Col onClick={()=>this.setState({videoplayer_popup:true,video_link:"https://storage.googleapis.com/iwd-event.appspot.com/help-videos/m9-user_register.mp4",video_title:"Slik melder deltakeren seg på"})} width="100" medium="50">
                <HelpVideosContainer img={`${process.env.PUBLIC_URL}/img/help_videos/register.jpg`} title="Slik melder deltakeren seg på" body="Denne videoen viser hvordan dine deltakere kan melde seg på deres arrangementer."/>
            </Col>
            <Col onClick={()=>this.setState({videoplayer_popup:true,video_link:"https://storage.googleapis.com/iwd-event.appspot.com/help-videos/v2-new_event.mp4",video_title:"Opprett et nytt arrangement"})} width="100" medium="50">
                <HelpVideosContainer img={`${process.env.PUBLIC_URL}/img/help_videos/new_event.jpg`} title="Opprett et nytt arrangement" body="Denne videoen viser deg hvordan du oppretter et nytt arrangement."/>
            </Col>
            <Col onClick={()=>this.setState({videoplayer_popup:true,video_link:"https://storage.googleapis.com/iwd-event.appspot.com/help-videos/v4-all_payments.mp4",video_title:"Se alle betalinger"})} width="100" medium="50">
                <HelpVideosContainer img={`${process.env.PUBLIC_URL}/img/help_videos/payments.jpg`} title="Se alle betalinger" body="Denne videoen viser deg hvordan du kan se alle betalinger fra dine deltakere."/>
            </Col>
            <Col onClick={()=>this.setState({videoplayer_popup:true,video_link:"https://storage.googleapis.com/iwd-event.appspot.com/help-videos/v10-checkin.mp4",video_title:"Innslipp og deltakerregistrering"})} width="100" medium="50">
                <HelpVideosContainer img={`${process.env.PUBLIC_URL}/img/help_videos/f4_checkin.jpg`} title="Innslipp og deltakerregistrering" body="Denne videoen viser deg hvordan du registrerer deltakere når de ankommer arrangementet."/>
            </Col>
            <Col onClick={()=>this.setState({videoplayer_popup:true,video_link:"https://storage.googleapis.com/iwd-event.appspot.com/help-videos/v5-privacy.mp4",video_title:"Se alle lagrede personopplysninger"})} width="100" medium="50">
                <HelpVideosContainer img={`${process.env.PUBLIC_URL}/img/help_videos/privacy.jpg`} title="Se alle lagrede personopplysninger" body="Denne videoen viser deg hvordan du kan se og slette personopplysninger fra dine deltakere."/>
            </Col>
            <Col onClick={()=>this.setState({videoplayer_popup:true,video_link:"https://storage.googleapis.com/iwd-event.appspot.com/help-videos/v3_new_payment_event.mp4",video_title:"Ta betalt fra dine deltakere"})} width="100" medium="50">
                <HelpVideosContainer img={`${process.env.PUBLIC_URL}/img/help_videos/charge.jpg`} title="Ta betalt fra dine deltakere" body="Denne videoen viser deg hvordan du tar betalt fra dine deltakere når de melder seg på et arrangement."/>
            </Col>
          </Row>
          <br/>
          <Popup animate={false} className="videoplayer-popup" opened={this.state.videoplayer_popup} onPopupClosed={() => this.setState({videoplayer_popup : false})}>
            {this.state.videoplayer_popup &&
            <Page themeDark>
                <Navbar title={this.state.video_title}>
                <NavRight>
                    <Link popupClose iconMaterial="close"></Link>
                </NavRight>
                </Navbar>
                <Suspense fallback={<div className="text-align-center" style={{margin:'26px'}}><Preloader></Preloader></div>}>
                    <VideoPlayer video={this.state.video_link}/>
                </Suspense>
            </Page>
            }
          </Popup>
      </>
    }
};