import React from 'react';
import {
    Page,
    Block,
    BlockTitle
} from 'framework7-react';
import {Helmet} from "react-helmet";

import IwdNavbar from '../shared/navbar/navbar';
import IwdBottomBar from '../shared/bottomBar/bottomBar';
import PageBlock from '../shared/pageBlock/pageBlock';

export default class extends React.Component {
  render() {
    return (
  <Page>
    <Helmet>
        <title>Påmeldinger.no - Epost</title>
        <meta name="description" content="Hvorfor mottar du epost av Påmeldinger.no"/>
        <meta property="og:title" content="Påmeldinger.no - Epost"/>
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/img/favicons/android-icon-192x192.png`}/>
        <meta name="description" content="Hvorfor mottar du epost av Påmeldinger.no"/>
        <meta property="og:type" content="website"/>
    </Helmet>
    <IwdNavbar/>
    <PageBlock width="650px">
      <BlockTitle large>Hvorfor mottar du e-post fra Påmeldinger.no</BlockTitle>
      <Block>
        <p>
        Du mottar epost fra Påmeldinger.no fordi du har meldt deg på et arrangement, eller på annen måte registrert deg på vår plattform.
        </p>
      </Block>
      <BlockTitle medium>Hva sender vi deg?</BlockTitle>
      <Block>
        <p>
        Påmeldinger.no sender deg aldri reklame. Likevel kan arrangører sende deg reklame dersom du har meldt deg på deres arrangement, og de har innhentet samtykke fra deg.
        </p>
        <p>
        Påmeldinger.no sender deg din billett på e-post. Arrangøren av arrangementet kan sende deg informasjon på e-postadressen du registrerte deg med.
        </p>
      </Block>
      <BlockTitle medium>Jeg ønsker ikke lenger å motta e-poster av dere.</BlockTitle>
      <Block>
        <p>
        Du kan reservere deg mot å motta ytterligere e-poster fra oss. (ikke-svar@pameldinger.no) Dersom du reserverer deg, vil du ikke lenger få dine billetter på e-post, og arrangøren kan ikke lenger sende deg eposter fra våre tjenester. 
        </p>
        <p>
            <a href="/kontakt" style={{textDecoration:'underline'}}>
                Ta kontakt med oss for å reservere deg mot fremtidige e-poster.
            </a>
        </p>
      </Block>
      <BlockTitle medium>Misbruk av din e-postadresse.</BlockTitle>
      <Block>
        <p>
        Dersom du har mottatt e-post fra oss uten at du har registrert deg på vår plattform, kan det tyde på at noen har misbrukt din e-postadresse.
        </p>
        <p>
        Dersom du tror noen har misbrukt din e-postadresse til å registrere deg på vår plattform, ønsker vi at du tar kontakt med oss.
        </p>
      </Block>
    </PageBlock>
    <IwdBottomBar/>
  </Page>);
  }
};