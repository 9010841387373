import * as React from "react";
import { Block, Col, Row, Link, BlockTitle } from "framework7-react";

import PageBlock from "../pageBlock/pageBlock";

export default class BottomBar extends React.Component {
  render() {
    return (
      <PageBlock width="740px" color="#f0f1f5">
        <Row noGap style={{ marginTop: "8px" }}>
          <Col width="100" small="50">
            <BlockTitle medium>Priser</BlockTitle>
            <Block>
              <p>Vår prismodell er enkel og transparent. Du betaler kun for bruk! Vi har ingen skjulte kostnader!</p>
              <p>
                Påmeldinger til gratisarrangementer <b>er gratis</b>. Påmeldinger som krever betaling fra deltakere, koster <b>4 kr + 3,5%</b> av totalbeløpet.<sup style={{ fontSize: "10px", opacity: ".7" }}>[1]</sup>
              </p>
              <Link style={{ textDecoration: "underline" }} href="/priser">
                <b>Se fullstending prisliste her.</b>
              </Link>
              <p style={{ opacity: 0.7 }}>1: Beløpet er et veiledende eksempel. Det er den til enhver tid oppgitte prisen til din organisasjon og transaksjonsavgiften fra betalingsleverandør som bestemmer den endelige prisen.</p>
            </Block>
          </Col>
          <Col width="100" small="50">
            <BlockTitle medium>Kontakt</BlockTitle>
            <Block>
              <p>
                Har du et spørsmål? Vi ønsker å hjelpe deg!{" "}
                <Link style={{ textDecoration: "underline" }} href="/kontakt">
                  <b>Her kan du ta kontakt med oss.</b>
                </Link>
              </p>
              <p>
                Spørsmål om faktura? Ta kontakt på e-post til:{" "}
                <Link external href="mailto:faktura@pameldinger.no">
                  <b>faktura@pameldinger.no</b>
                </Link>
              </p>
              <p>
                <b>Ikke arrangør?</b> Dersom du har spørsmål om et arrangement, må du ta kontakt direkte med arrangøren av arrangementet.
              </p>
            </Block>
          </Col>
        </Row>
        <Row noGap>
          <Col width="100" small="50">
            <BlockTitle medium>Annet</BlockTitle>
            <Block>
              <p>
                <Link href="/vilkar-for-deltakere">Vilkår for deltakere</Link>
              </p>
              <p style={{ marginTop: "-8px" }}>
                <Link href="/vilkar-for-arrangor">Vilkår for arrangører</Link>
              </p>
              <p style={{ marginTop: "-8px" }}>
                <Link href="/vilkar-for-brukere">Vilkår for brukere</Link>
              </p>
              <p style={{ marginTop: "-8px" }}>
                <Link href="/cookies">Informasjonskapsler (Cookies)</Link>
              </p>
              <p style={{ marginTop: "-8px" }}>
                <Link href="/databehandleravtale">Databehandleravtale</Link>
              </p>
              <p style={{ marginTop: "-8px" }}>
                <Link href="/personvern">Personvernerklæring</Link>
              </p>
            </Block>
          </Col>
        </Row>
        <Block className="text-align-center" style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}>
          <p style={{ opacity: 0.5 }}>PÅMELDINGER.NO AS | Org.Nr. 821 190 932 MVA</p>
        </Block>
      </PageBlock>
    );
  }
}
