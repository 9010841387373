import React, { Suspense, lazy } from "react";
import { Button, Icon } from "framework7-react";
import "./greeting.css";
//const ParticlesBlock = lazy(() => import('./particles'));

export default class Greeting extends React.Component {
  render() {
    return (
      <div className="greeting_block">
        <div className="greeting-logo"></div>
        <div className="left-menu">
          <a href="/funksjoner" className="hideMobileScreen c-link">
            Funksjoner
          </a>
          {/*
          <a href="/funksjoner" className="hideMobileScreen c-link">
            Referanser
          </a>
          */}
          <a href="/kontakt" className="hideMobileScreen c-link">
            Kontakt oss
          </a>
          <a href="/priser" className="hideMobileScreen c-link">
            Priser
          </a>
          <a href="/hjelp" className="hideMobileScreen c-link">
            Hjelp
          </a>
          <Button href="https://admin.pameldinger.no" className="hideMobileScreen" round external outline large color="white" style={{ paddingLeft: "12px", paddingRight: "12px" }}>
            <Icon style={{ marginRight: "2px" }} material="account_circle"></Icon> Logg inn
          </Button>

          <Button panelOpen="right" round large className="NavbarIconView hideFullScreen" iconMaterial="menu" style={{ color: "#ffffff", marginRight: "5px", marginLeft: "4px" }} />
        </div>
        <div className="greeting_text_block">
          <div className="greeting_text_inner">
            <div className="greeting_header">Vi forenkler påmeldinger og billettsalg på nett!</div>
            <div className="greeting_body">Påmeldinger.no er et påmeldingssystem for arrangementer, kurs og events. Vi tar oss av betalinger og administrativt arbeid i forbindelse med arrangementer.</div>
            <Button href="/kom-i-gang" className="greeting_btn" fill style={{ backgroundColor: "#FF0081" }} large round>
              Prøv gratis
            </Button>
          </div>
        </div>
        <div className="greeting-particles" />
      </div>
    );
  }
}
