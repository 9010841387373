import React from 'react';
import {
    Page,
    Block,
    BlockTitle
} from 'framework7-react';
import {Helmet} from "react-helmet";

import IwdNavbar from '../shared/navbar/navbar';
import IwdBottomBar from '../shared/bottomBar/bottomBar';
import PageBlock from '../shared/pageBlock/pageBlock';

export default class extends React.Component {
  render() {
    return (
  <Page>
    <Helmet>
        <title>Påmeldinger.no - Informasjonskapsler (Cookies)</title>
        <meta name="description" content="Hvordan Påmeldinger.no bruker Informasjonskapsler (Cookies)."/>
        <meta property="og:title" content="Påmeldinger.no - Informasjonskapsler (Cookies)"/>
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/img/favicons/android-icon-192x192.png`}/>
        <meta name="description" content="Hvordan Påmeldinger.no bruker Informasjonskapsler (Cookies)."/>
        <meta property="og:type" content="website"/>
    </Helmet>
    <IwdNavbar/>
    <PageBlock width="650px">
      <BlockTitle large>Informasjonskapsler (cookies)</BlockTitle>
      <Block>
        <p>Informasjonskapsler hjelper oss å gi en bedre brukeropplevelse. Påmeldinger.no bruker Informasjonskapsler i våre nettløsninger.</p>
      </Block>
      <BlockTitle medium>Samtykke</BlockTitle>
      <Block>
        <p>Ved å benytte Påmeldinger.no sine nettsider samtykker du i at vi kan etterlate informasjonskapsler i din nettleser. De fleste moderne nettlesere (Google Chrome, Internet Explorer, Firefox, Safari, Opera, etc.) er innstilt på å akseptere informasjonskapsler automatisk. <b>Dersom du ønsker å deaktivere informasjonskapsler for din nettleser, kan du gjøre dette via nettleserens sikkerhetsinnstillinger.</b></p>
      </Block>
      <BlockTitle medium>Hva er en informasjonskapsel?</BlockTitle>
      <Block>
        <p>En informasjonskapsel, ofte kalt cookie, er en tekstfil som lastes ned og lagres på datamaskinen når du besøker et nettsted. Informasjonskapselen kan benyttes til å lagre tidligere brukerinnstillinger, registrere hvilke sider du besøker og hvilke valg du gjør mens du bruker dem. Du kan lese mer om informasjonskapsler og hvordan du bruker ved å klikke på hjelp i nettleseren din.</p>
      </Block>
      <BlockTitle medium>Hva bruker Påmeldinger.no informasjonskapsler til?</BlockTitle>
      <Block>
        <p>Påmeldinger.no benytter <i>aldri</i> informasjonskapsler i den hensikt å kartlegge enkeltbrukeres bruksmønster eller annen informasjon som vil kunne krenke personvernet.</p>
        <p>Påmeldinger.no bruker informasjonskapsler til følgende formål:</p>
        <ul>
            <li>
                for å lagre brukerinnstillinger — som f.eks. visningsspråk
            </li>
            <li>
                for å håndtere pålogging og sesjoner
            </li>
            <li>
                for å samle statistikk til å forbedre Påmeldinger.no sine nettsider
            </li>
        </ul>
        <p>
        Påmeldinger.no får sin statistikk fra Google Analytics. Det medfører at din bruk av våre nettsider, inklusiv din IP-adresse, kan bli overført til Google.
        </p>
      </Block>
    </PageBlock>
    <IwdBottomBar/>
  </Page>);
  }
};