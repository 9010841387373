import * as React from 'react';
import {
    Block,
    BlockTitle
} from 'framework7-react';

import PageBlock from '../../../shared/pageBlock/pageBlock';
import './infoCards.css';

class InfoCard extends React.Component {
    render() {
        return <div className="project-card">
            
            <div className={`project-card-bg hideFullScreen ${this.props.img}`} style={{height:'240px'}}/>

            <div className="row-card">
                {!this.props.right &&
                    <div className={`col-card project-card-bg hideMobileScreen ${this.props.img}`}/>
                }
                <div className="col-card">
                    <BlockTitle medium>
                        {this.props.title}
                    </BlockTitle>
                    <Block>
                        <p>
                        {this.props.text}
                        </p>
                    </Block>
                </div>
                {this.props.right &&
                    <div className={`col-card project-card-bg hideMobileScreen ${this.props.img}`}/>
                }
            </div>
        </div>
    }
}

export default class InfoCards extends React.Component {

    render() {
    return <PageBlock width="880px" >
        <br/>
        <InfoCard right img="bg_img_4" title="Gratis å bruke" text={<>
            <p>
                    Påmeldinger.no er gratis å bruke på gratisarrangementer.
                    </p>
                    <p>
                    For påmeldinger til arrangementer som koster penger, tar vi oss av <a style={{textDecoration:'underline'}} href="/ta-betalt">betaling fra deltakeren</a>, og du får penger rett inn på konto. <a style={{textDecoration:'underline'}} href="/priser">For dette tar vi et lite transaksjonsgebyr.</a>
                    </p>
                    <p>
                        <b>
                        Vi har ingen oppstartsgebyr eller skjulte kostnader.
                        </b>
                    </p>
        </>}/>
        <InfoCard img="bg_img_1" title="Full kontroll" text={<>
            <p>
                        Påmeldinger.no gir deg full kontroll over påmeldinger og billetter. Du får kontinuerlig oversikt over hvem som er påmeldt ditt arrangement, og hvem som har møtt opp.
                    </p>
                    <p>
                        Du får også sanntidsoversikt over innbetalinger og utbetalinger.
                    </p>
                    <p>
                        <b>
                            Få full oversikt over deltakere, salg og utbetalinger!
                        </b>
                    </p>
        </>}/>
        <InfoCard right img="bg_img_3" title="Skreddersydd ditt behov" text={<>
            <p>
                        Du kan tilpasse påmeldingene etter dine behov. Du kan legge til felter deltakerne må svare på når de registrerer seg, og du kan tilpasse fargene til din visuelle profil.
                    </p>
                    <p>
                        <b>
                        Vi tilbyr et fleksibelt påmeldingssystem som kan tilpasses alle behov og arrangementer.
                        </b>
                    </p>
        </>}/>
        <br/>
      </PageBlock>
    }
};