import * as React from "react";
import { Row, Col, Card, CardContent, Button, Block } from "framework7-react";

import PageBlock from "../../../shared/pageBlock/pageBlock";
import { HelpVideosSmall } from "../../help/helpVideos/helpVideos";

import "./referencesBlock.css";

class GetStartedCard extends React.Component {
  render() {
    return (
      <Card className="get_started_card">
        <CardContent>
          <div className="step_nr">{this.props.nr}</div>
          <h5 className="text-color-white">{this.props.title}</h5>
          {this.props.children}
        </CardContent>
      </Card>
    );
  }
}

export default class ReferencesBlock extends React.Component {
  render() {
    return (
      <div className="get_started_block">
        <PageBlock width="720px" color="var(--f7-theme-color)">
          <h2 className="text-align-center text-color-white">Du er i godt selskap</h2>
          <Block className="text-align-center text-color-white">
            <p style={{ fontSize: "18px" }}>Over 500 organisasjoner og bedrifter stoler på oss for å håndtere påmeldinger til deres arrangementer. Siden vi startet opp i 2020, har over 250 000 deltakere registrert seg gjennom oss, og Påmeldinger.no er brukt på over 5 000 arrangementer.</p>
          </Block>
          <br />
        </PageBlock>
      </div>
    );
  }
}
