import React from 'react';
import {
    Page,
    Block,
    BlockTitle
} from 'framework7-react';
import {Helmet} from "react-helmet";

import IwdNavbar from '../shared/navbar/navbar';
import IwdBottomBar from '../shared/bottomBar/bottomBar';
import PageBlock from '../shared/pageBlock/pageBlock';

export default class extends React.Component {
  render() {
    return (
  <Page>
    <Helmet>
        <title>Påmeldinger.no - Ditt personvern</title>
        <meta name="description" content="Her finner du informasjon om hvordan dine personopplysninger blir behandlet, hvem som har tilgang til dine personopplysninger, hvor lenge dine personopplysninger lagres og hvordan du går frem for å kreve at dine personopplysninger slettes."/>
        <meta property="og:title" content="Påmeldinger.no - Ditt personvern"/>
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/img/favicons/android-icon-192x192.png`}/>
        <meta name="description" content="Her finner du informasjon om hvordan dine personopplysninger blir behandlet, hvem som har tilgang til dine personopplysninger, hvor lenge dine personopplysninger lagres og hvordan du går frem for å kreve at dine personopplysninger slettes."/>
        <meta property="og:type" content="website"/>
    </Helmet>
    <IwdNavbar/>
    <PageBlock width="650px">
      <BlockTitle large>Dine personopplysninger</BlockTitle>
      <Block>
        <p>
        Når du melder deg på et arrangement samler vi inn personopplysninger om deg. Ditt personvern er veldig viktig for oss, og vi ønsker at du skal være trygg på at dine opplysninger behandles på en trygg måte. Derfor har vi laget denne informasjonssiden for å vise deg hvordan vi behandler dine personopplysninger når du melder deg på et arrangement.
        </p>
      </Block>
      <BlockTitle medium>Hvem samler inn personopplysninger?</BlockTitle>
      <Block>
        <p>
        Når du melder deg på et arrangement samler Påmeldinger.no inn dine opplysninger på vegne av <b>arrangører av arrangementet</b>. Det er <b>kun</b> arrangøren av arrangementet som har tilgang til opplysningene som samles inn fra deg.
        </p>
      </Block>
      <BlockTitle medium>Hvilke opplysninger samles inn?</BlockTitle>
      <Block>
        <p>
        Når du melder deg på et arrangement gjennom Påmeldinger.no, samler vi inn de opplysningene du oppgir i påmeldingsskjemaet. Disse opplysningene variere fra arrangement til arrangement, og bestemmes av arrangøren. Opplysningene vil likevel alltid inkludere: <i>Navnet ditt</i>., <i>telefonnummeret ditt</i>. og <i>e-postadressen din</i>.
        </p>
      </Block>
      <BlockTitle medium>Hvor lenge lagres mine personopplysninger?</BlockTitle>
      <Block>
        <p>
        Det er arrangøren som arrangerer arrangementet du har meldt deg på som bestemmer hvor lenge dine personopplysninger lagres. De fleste arrangører har ikke behov for å lagre dine personopplysninger etter at arrangementet er avsluttet. Dersom du ønsker å vite mer om hvordan arrangøren behandler dine personopplysninger og hvor lenge de lagres, må du ta kontakt med arrangøren av arrangementet.
        </p>
        <p>
        Husk at du har rett til å kreve at arrangøren forteller deg hvilke opplysninger som er samlet inn om deg, og hvor lenge de lagres. Du har også rett til å kreve at arrangøren sletter opplysninger om deg, når de ikke lenger har behandlingsgrunnlag for dem.
        </p>
      </Block>
      <BlockTitle medium>Hvordan kontakte arrangøren?</BlockTitle>
      <Block>
        <p>
        Du skal ha mulighet til å ta kontakt med alle arrangører som bruker Påmeldinger.no til å administrere sitt arrangement. Du finner arrangørens kontaktinformasjon på påmeldingssiden til hvert arrangement, og i e-postbekreftelsen du mottar når du melder deg på et arrangement.
        </p>
      </Block>
    </PageBlock>
    <IwdBottomBar/>
  </Page>);
  }
};