import * as React from 'react';
import {
    Row,
    Col,
    Card,
    CardContent,
    Button,
    Block
} from 'framework7-react';

import PageBlock from '../../../shared/pageBlock/pageBlock';
import {HelpVideosSmall} from '../../help/helpVideos/helpVideos';

import './getStartedBlock.css';


class GetStartedCard  extends React.Component {
  render() {
    return <Card className="get_started_card">
      <CardContent>
        <div className="step_nr">
          {this.props.nr}
        </div>
        <h5 className="text-color-white">
          {this.props.title}
        </h5>
        {this.props.children}
      </CardContent>
    </Card>
  }
}

export default class GetStartedBlock extends React.Component {

    render() {
      return <div className="get_started_block">
      <PageBlock width="960px">
        <h2 className="text-align-center text-color-white">Kom i gang</h2>
        <Row noGap>
            <Col width="100" medium="33">
              <GetStartedCard title="Opprett konto" nr={1}>
                <p>
                  Opprett en konto - <b>helt gratis</b>
                </p>
                <Button style={{marginTop:'26px',backgroundColor:'#FF0081'}} href="/kom-i-gang" fill large round>Prøv gratis nå</Button>
              </GetStartedCard>
            </Col>
            <Col width="100" medium="33">
              <GetStartedCard title="Påmeldinger" nr={2}>
                <p>
                  Opprett ditt første arrangement. Send ut lenke, og få påmeldinger.
                </p>
                <p>
                  Følg påmeldingene i sanntid.
                </p>
              </GetStartedCard>
            </Col>
            <Col width="100" medium="33">
              <GetStartedCard title="Arranger" nr={3}>
                <p>
                  Opprett ditt første arrangement.
                </p>
                <p>
                  Vi tar oss av påmeldinger, betalinger og administrering.
                </p>
              </GetStartedCard>
            </Col>
        </Row>
        <br/>
      </PageBlock>
      <PageBlock width="720px" color="var(--f7-theme-color)">
        <Block className="text-align-center text-color-white">
            <p style={{fontSize:'20px'}}>
            Vi har laget noen videoer som viser deg hvor enkelt det er å bruke Påmeldinger.no, og hva du må gjøre for å opprette et arrangement.
            </p>
        </Block>
      </PageBlock>
      <PageBlock width="860px" color="var(--f7-theme-color)">
        <HelpVideosSmall/>
      </PageBlock>
      <PageBlock width="720px" color="var(--f7-theme-color)">
        <Block className="text-align-center text-color-white">
            <p style={{fontSize:'18px'}}>
            Har du flere spørsmål til hvordan du bruker Påmeldinger.no, og hva du må tenke på når du bruker oss?
            </p>
            <a href="/hjelp" style={{fontSize:'18px',color:'#fff',textDecoration:'underline'}}>
              På hjelp-siden vår finner du svar på det meste du kan tenke deg.
            </a>
        </Block>
        <br/>
      </PageBlock>
    </div>
    }
};