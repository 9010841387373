import React from "react";
import { Page } from "framework7-react";
import { Helmet } from "react-helmet";

import IwdBottomBar from "../../shared/bottomBar/bottomBar";

import Greeting from "./greeting/greeting";
import InfoCards from "./infoCards/infoCards";
import FeaturesBlock from "./featuresBlock/featuresBlock";
import GetStartedBlock from "./getStartedBlock/getStartedBlock";
import ReferencesBlock from "./referencesBlock/referencesBlock";

export default class extends React.Component {
  render() {
    return (
      <Page>
        <Helmet>
          <title>Påmeldinger.no - Enklere påmeldinger og billettsalg!</title>
          <meta name="description" content="Påmeldinger.no er et påmeldingssystem for konferanser, kurs, konserter og events. Vi tar oss av betalinger og administrativt arbeid i forbindelse med arrangementer." />
          <meta property="og:title" content="Påmeldinger.no - Enklere påmeldinger og billettsalg" />
          <meta property="og:image" content={`${process.env.PUBLIC_URL}/img/favicons/android-icon-192x192.png`} />
          <meta property="og:description" content="Påmeldinger.no er et påmeldingssystem for konferanser, kurs, konserter og events. Vi tar oss av betalinger og administrativt arbeid i forbindelse med arrangementer." />
          <meta property="og:type" content="website" />
        </Helmet>
        <Greeting />
        <InfoCards />
        <FeaturesBlock />
        <ReferencesBlock />
        <GetStartedBlock />
        <IwdBottomBar />
      </Page>
    );
  }
}
