import React, { Suspense, lazy } from "react";
import { f7, Page as F7Page, Preloader } from "framework7-react";
import { Helmet } from "react-helmet";
import "./terms.css";

import IwdNavbar from "../../shared/navbar/navbar";
import IwdBottomBar from "../../shared/bottomBar/bottomBar";

const TermsContainer = lazy(() => import("./termsContainer"));

export default class extends React.Component {
  render() {
    const path = f7.views.main.router.url;
    let title;
    switch (path) {
      case "/vilkar-for-deltakere":
        title = "Vilkår for deltakere";
        break;
      case "/vilkar-for-arrangor":
        title = "Vilkår for arrangører";
        break;
      case "/vilkar-for-brukere":
        title = "Vilkår for brukere";
        break;
      case "/cookies":
        title = "Informasjonskapsler (Cookies)";
        break;
      case "/databehandleravtale":
        title = "Databehandleravtale";
        break;
      case "/personvern":
        title = "Personvernerklæring";
        break;
      default:
        title = "404";
    }

    return (
      <F7Page>
        <Helmet>
          <title>Påmeldinger.no - {title}</title>
          <meta name="description" content={title} />
          <meta property="og:title" content={`Påmeldinger.no - ${title}`} />
          <meta property="og:image" content={`${process.env.PUBLIC_URL}/img/favicons/android-icon-192x192.png`} />
          <meta property="og:description" content={`Påmeldinger.no - ${title}`} />
          <meta property="og:type" content="website" />
        </Helmet>
        <IwdNavbar />
        <Suspense
          fallback={
            <div className="text-align-center" style={{ marginTop: "44px", marginBottom: "660px" }}>
              <Preloader></Preloader>
              <p>Vent litt</p>
            </div>
          }
        >
          <TermsContainer />
        </Suspense>
        <IwdBottomBar />
      </F7Page>
    );
  }
}
