import React from 'react';
import {
    Page,
    Row,
    Col,
    BlockTitle,
    Block,
    Link,
    Button,
    Icon,
    Card,
    CardContent
} from 'framework7-react';
import {Helmet} from "react-helmet";

import './main.css';
import IwdNavbar from '../../shared/navbar/navbar';
import IwdBottomBar from '../../shared/bottomBar/bottomBar';

import PageBlock from '../../shared/pageBlock/pageBlock';

export default class extends React.Component {
    render() {
        return <Page>
            <Helmet>
                <title>Påmeldinger.no - Våre funksjoner</title>
                <meta name="description" content="Her finner du de funksjonene vi er mest stolt over å tilby våre brukere."/>
                <meta property="og:title" content="Påmeldinger.no - Våre funksjoner"/>
                <meta property="og:image" content={`${process.env.PUBLIC_URL}/img/favicons/android-icon-192x192.png`}/>
                <meta property="og:description" content="Her finner du de funksjonenevi er mest stolt over å tilby våre brukere."/>
                <meta property="og:type" content="website"/>
            </Helmet>
            <IwdNavbar/>
            <PageBlock width="660px">
                <BlockTitle large>
                    Våre funksjoner
                </BlockTitle>
                <Block>
                    <p style={{fontSize:'20px'}}>
                    På denne siden finner du de funksjonene vi er mest stolt over å tilby våre brukere. Du kan <Link style={{textDecoration:'underline'}} href="/kom-i-gang">opprette en gratis bruker</Link> for selv å teste funksjonaliteten til systemet, og bestemme om Påmeldinger.no er rett for din organisasjon.
                    </p>
                </Block>
            </PageBlock>
            <PageBlock width="860px" color="#f0f1f5">
                <Row noGap>
                    <Col width="100" medium="50">
                        <Icon className="functions-page-icon" size={76} f7='pencil_ellipsis_rectangle'/>
                        <BlockTitle medium>
                        Tilpasset påmelding
                        </BlockTitle>
                        <Block>
                            <p>
                            Med Påmeldinger.no kan du selv bestemme hvilke opplysninger dine deltakere skal oppgi i påmeldingen. I tillegg til opplysninger som navn og telefonnummer, kan du legge til egne spørsmål. Du kan for eksempel spørre om studieretning eller allergier, og du kan velge mellom fritekst, multiple choice og checkboxes.
                            </p>
                        </Block>
                    </Col>
                    <Col width="100" medium="50">
                        <Icon className="functions-page-icon" size={76} f7='creditcard'/>
                        <BlockTitle medium>
                        Ta betalt fra dine deltakere
                        </BlockTitle>
                        <Block>
                            <p>
                            Vi kan ta betalt fra dine deltakere. Du velger selv om det skal koste penger å melde seg på et arrangement.
                            </p>
                            <p>
                            Deltakeren kan betaler med bankkort eller Vipps for å melde seg på, og du får penger på konto etter få dager.
                            </p>
                            <p>
                                <a href="/ta-betalt" style={{textDecoration:'underline'}}><b>
                                Finn ut mer om billettsalg.
                                </b></a>
                            </p>
                        </Block>
                    </Col>
                    <Col width="100" medium="50">
                        <Icon className="functions-page-icon" size={76} f7='graph_square'/>
                        <BlockTitle medium>
                        Full oversikt
                        </BlockTitle>
                        <Block>
                            <p>
                            Som arrangør får du full oversikt over arrangementet dine når du bruker Påmeldinger.no. Du får oversikt over arrangementer, påmeldte deltakere, kjøp, utbetalinger og mye annet.
                            </p>
                            <p>
                            Dersom det er flere personer i din organisasjon som har behov for å ha tilgang til arrangementene, kan du enkelt legge til flere brukere.
                            </p>
                        </Block>
                    </Col>
                    <Col width="100" medium="50">
                        <Icon className="functions-page-icon" size={76} f7='calendar_today'/>
                        <BlockTitle medium>
                        Organisasjonsside for arrangementer.
                        </BlockTitle>
                        <Block>
                            <p>
                            Med Påmeldinger.no har du mulighet til å samle alle dine arrangementer på en side. På din organisasjons sin side over arrangementer, vil alle dine arrangementer vises til dine deltakere. Siden opprettes på internettadressen: <b>valgfritt-navn.pameldinger.no</b>.
                            </p>
                        </Block>
                    </Col>
                    <Col width="100" medium="50">
                        <Icon className="functions-page-icon" size={76} f7='repeat'/>
                        <BlockTitle medium>
                        Løpende utbetalinger
                        </BlockTitle>
                        <Block>
                            <p>
                            Alle salg fra dine arrangementer utbetales løpende 7 dager etter at salget ble gjennomført. Du kan også velge å få alle betalingen samlet på en spesifikk dag i måneden eller uken.
                            <br/>
                            <br/>
                            <a href="/ta-betalt" className="c-text-link">
                            Finn ut mer om salg og utbetalinger.
                            </a>
                            </p>
                        </Block>
                    </Col>
                    <Col width="100" medium="50">
                        <Icon className="functions-page-icon" size={76} f7='envelope'/>
                        <BlockTitle medium>
                        Varslinger på E-post og SMS
                        </BlockTitle>
                        <Block>
                            <p>
                            Deltakerne dine får automatisk en e-post eller SMS når de melder seg på. Her finner de opplysninger om arrangementet og sin påmelding.
                            </p>
                            <p>
                            Du kan også sende ut egne e-post eller SMS varslinger til dine deltakere.
                            </p>
                        </Block>
                    </Col>
                    <Col width="100" medium="50">
                        <Icon className="functions-page-icon" size={76} f7='qrcode_viewfinder'/>
                        <BlockTitle medium>
                        Enkel innsjekking og registrering
                        </BlockTitle>
                        <Block>
                            <p>
                            Det skal være enkelt å sjekke inn en påmeldt deltaker. Vi har utviklet et enkelt og effektivt system for å registrere en deltaker. Alle deltakere som melder seg på får en unik QR-kode. Gjennom vårt system kan du bruke din egen smarttelefon til å registrere deltakere når de ankommer.
                            </p>
                        </Block>
                    </Col>
                    <Col width="100" medium="50">
                        <Icon className="functions-page-icon" size={76} f7='alt'/>
                        <BlockTitle medium>
                        API-integrasjon
                        </BlockTitle>
                        <Block>
                            <p>
                            Vi tilbyr enkel og intuitiv API-integrasjon. Dersom du vil integrere Påmeldinger.no kan du vise dine arrangementer og ta betalt direkte på din egen side.
                            </p>
                        </Block>
                    </Col>
                    <Col width="100" medium="50">
                        <Icon className="functions-page-icon" size={76} f7='person_2'/>
                        <BlockTitle medium>
                        Venteliste
                        </BlockTitle>
                        <Block>
                            <p>
                            På arrangementer med begrenset antall plasser, kan dere ha ventelister. Deltakere blir automatisk satt på venteliste dersom arrangementet er fullt.
                            </p>
                            <p>
                            Deltakeren kan følge sin plass i køen og blir varslet dersom han får plass på arrangement.
                            </p>
                        </Block>
                    </Col>
                    <Col width="100" medium="50">
                        <Icon className="functions-page-icon" size={76} f7='paintbrush'/>
                        <BlockTitle medium>
                        Egen visuell profil
                        </BlockTitle>
                        <Block>
                            <p>
                            Du kan velge en egendefinert visuell profil på ditt påmeldingsskjema. På den måten kjenner dine deltakere igjen designet til din organisasjon når de melder seg på.
                            </p>
                            <p>
                            Egen visuell profil inkluderer egne farger og logo. Det er enkel å sette opp i vår påmeldingsskjemabygger.
                            </p>
                        </Block>
                    </Col>
                    <Col width="100" medium="50">
                        <Icon className="functions-page-icon" size={76} f7='lock_shield'/>
                        <BlockTitle medium>
                        Personvern i sentrum
                        </BlockTitle>
                        <Block>
                            <p>
                            Dersom du skal bruke personopplysningene til formål som for eksempel nyhetsbrev, direkte markedsføring eller aksept av brukerbetingelser, må du hente inn et aktivt samtykke for hvert enkelt formål.
                            </p>
                            <p>
                            Du kan enkelt samle inn samtykker gjennom påmeldingsskjemaet vårt.
                            </p>
                        </Block>
                    </Col>
                    <Col width="100" medium="50">
                        <Icon className="functions-page-icon" size={76} f7='person_3'/>
                        <BlockTitle medium>
                        Gruppe-/familiepåmelding
                        </BlockTitle>
                        <Block>
                            <p>
                            Du kan velge at flere deltakere kan melde seg på samtidig. Da melder deltakeren seg på gjennom samme skjema og mottar en felles billett.
                            </p>
                        </Block>
                    </Col>
                </Row>
            </PageBlock>
            <PageBlock width="780px">
                <Row>
                    <Col width="100" medium="50">
                        <BlockTitle medium>
                            Prøv ut Påmeldinger.no helt gratis!
                        </BlockTitle>
                        <Block style={{marginTop:0}}>
                            <p>
                            Vi får ikke vist deg all funksjonaliteten vi kan tilby. Vi håper derfor du selv vil teste Påmeldinger.no. Det er helt gratis og uforpliktende å teste oss ut, og du bestemmer selv om vi er rett for din organisasjon.
                            </p>
                            <br/>
                            <Button href="/kom-i-gang" round large fill>Prøv gratis</Button>
                        </Block>
                    </Col>
                    <Col width="100" medium="50">
                        <Card outline style={{marginTop:'24px'}}>
                            <CardContent padding={false} style={{height:'250px'}} className={'i-card-img bg_img_4'}/>
                        </Card>
                    </Col>
                </Row>
            </PageBlock>
            <IwdBottomBar/>
        </Page>
    }
};
