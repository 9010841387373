import React from 'react';
import {
    Page,
    Row,
    Col,
    BlockTitle,
    Block,
    Button,
    Card,
    CardContent,
    Link,
    Icon
} from 'framework7-react';
import {Helmet} from "react-helmet";
import './getStartedPage.css';

import IwdNavbar from '../../shared/navbar/navbar';
import IwdBottomBar from '../../shared/bottomBar/bottomBar';

import PageBlock from '../../shared/pageBlock/pageBlock';

export default class extends React.Component {
    render() {
        return <Page>
            <Helmet>
                <title>Påmeldinger.no - Kom i gang</title>
                <meta name="description" content="Her viser vi deg hvordan du oppretter ditt første arrangement. Følg denne oppstartsguiden, og du vil være klar om noen få minutter."/>
                <meta property="og:title" content="Påmeldinger.no - Kom i gang"/>
                <meta property="og:image" content={`${process.env.PUBLIC_URL}/img/favicons/android-icon-192x192.png`}/>
                <meta property="og:description" content="Her viser vi deg hvordan du oppretter ditt første arrangement. Følg denne oppstartsguiden, og du vil være klar om noen få minutter."/>
                <meta property="og:type" content="website"/>
            </Helmet>
            <IwdNavbar/>
            <PageBlock width="660px">
                <BlockTitle large>
                    Kom i gang
                </BlockTitle>
                <Block>
                    <b>
                    Takk for at du vil prøve Påmeldinger.no! Følg denne oppstartsguiden, og du vil være klar om noen få minutter.
                    </b>
                    <p>
                    Denne guiden hjelper deg å sette opp en konto for din organisasjon. Den hjelper deg å opprette din bruker, og legge til andre brukere i din organisasjon. Til slutt hjelper den deg å opprette ditt første arrangement.
                    </p>
                    <p>
                    Vi har laget en videogjennomgang av hvordan du oppretter ditt første arrangement. <Link style={{textDecoration:'underline'}} href="/hjelp"><b>Her finner du videogjennomgangen vi har laget.</b></Link>
                    </p>
                </Block>
            </PageBlock>
            <PageBlock width="860px" color="#dfe4ea">
                <Row noGap>
                    <Col width="100" medium="50">
                        <BlockTitle medium>
                            1. Opprett din bruker
                        </BlockTitle>
                        <Block>
                            <p>
                                For å bruke <b>Påmeldinger.no</b> må du opprette en bruker. Brukeren din er personlig. Du skal <b>ikke</b> opprette en bruker for din bedrift. Trykk på knappen under for å opprette bruker.
                            </p>
                            <Button round target="_blank" href="https://admin.pameldinger.no/register" external style={{backgroundColor:'#FF0081',margin:'34px auto',maxWidth:'300px'}} fill>Opprett bruker</Button>
                            <p>
                                Returner til denne siden når du har opprettet din bruker.
                            </p>
                        </Block>
                    </Col>
                    <Col width="100" medium="50">
                        <Card outline style={{marginTop:'26px', background:'none', border:'none'}}>
                            <CardContent className="gsp-card-1" padding={false}/>
                        </Card>
                    </Col>
                </Row>
            </PageBlock>
            <PageBlock width="860px">
                <Row noGap>
                    <Col width="100" medium="50">
                        <BlockTitle medium>
                            2. Opprett organisasjon
                        </BlockTitle>
                        <Block>
                            <p>
                                Når du har opprettet din egen bruker, må du opprette en organisasjon. Organisasjonen er vanligvis din bedrift.
                            </p>
                            <p>
                               Trykk på <b><Icon size={18} material="add"></Icon>Ny organisasjon</b> for å opprette en ny organsisasjon.
                            </p>
                            <p>
                            Du kan legge til så mange brukere du ønsker i din organisasjon. <a style={{textDecoration:'underline'}} href="/hjelp">Se hvordan du legger til flere brukere i en organisasjon.</a>
                            </p>
                        </Block>
                    </Col>
                    <Col width="100" medium="50">
                        <Card outline style={{marginTop:'26px', background:'none', border:'none'}}>
                            <CardContent padding={false}  className="gsp-card-2"/>
                        </Card>
                    </Col>
                </Row>
            </PageBlock>
            <PageBlock width="860px" color="#dfe4ea">
                <Row noGap>
                    <Col width="100" medium="50">
                        <BlockTitle medium>
                            3. Opprett påmelding
                        </BlockTitle>
                        <Block>
                            <p>
                                Du er nå klar til å opprette din første påmelding. Trykk på <b><Icon size={18} material="add"></Icon>Lag ny påmelding</b> i menyen på venstre side.
                            </p>
                            <p>
                                Følg anvisningene på siden for å opprette påmeldingen Du kan tilpasse påmeldingskjemaet etter ditt behov.
                            </p>
                            <p>
                                Du velger hvor mye det skal koste for en deltaker å melde seg på, og om det skal være flere påmeldingstyper.
                            </p>
                            <p>
                                Når du er ferdig får du en lenke som heter <b>Lenke til påmelding</b>, som du sender til dine potensielle deltakere,
                            </p>
                        </Block>
                    </Col>
                    <Col width="100" medium="50">
                        <Card outline style={{marginTop:'26px', background:'none', border:'none'}}>
                            <CardContent className="gsp-card-3" padding={false}/>
                        </Card>
                    </Col>
                </Row>
            </PageBlock>
            <PageBlock width="860px">
                <Row noGap>
                    <Col width="100" medium="50">
                        <BlockTitle medium>
                            4. Administrer arrangement
                        </BlockTitle>
                        <Block>
                            <p>
                                Fra kontrollpanelet kan du følg med på påmelding i sanntid. Du har oversikt over hvor mange som har meldt seg på, og hvilke opplysninger de har oppgitt.
                            </p>
                            <p>
                                Trykk på <b>Vis alle deltakere</b> for å få en fullstendig liste over alle påmeldte deltakere.
                            </p>
                            <p>
                                Trykk på <b>Se alle salg</b> for å få en fullstendig oversikt over alle salgt.
                            </p>
                        </Block>
                    </Col>
                    <Col width="100" medium="50">
                        <Card outline style={{marginTop:'26px', background:'none', border:'none'}}>
                            <CardContent padding={false}  className="gsp-card-4"/>
                        </Card>
                    </Col>
                </Row>
            </PageBlock>
            <PageBlock width="660px">
                        <BlockTitle medium>
                            Det var det!
                        </BlockTitle>
                        <Block>
                            <p>
                                Nå har du satt opp ditt første arrangement. Vi tar oss av påmeldinger, betalinger og administrering, så du kan bruke tiden på å skape gode opplevelser på ditt arrangement!
                            </p>
                            <p>
                                Har du andre spørsmål, eller lurer på noe om de andre funksjonene til <b>Påmeldinger.no</b>? Ta en titt på vår <Link style={{textDecoration:'underline'}} href="/hjelp"><b>Hjelp-side</b></Link> og våre <Link style={{textDecoration:'underline'}} href="/funksjoner"><b>Funksjoner</b></Link>. Du kan også <Link style={{textDecoration:'underline'}} href="/kontakt"><b>ta kontakt</b></Link> med oss, så hjelper vi deg så raskt som mulig!
                            </p>
                        </Block>
                        <br/>
            </PageBlock>
            <IwdBottomBar/>
        </Page>
    }
};
