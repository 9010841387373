import React from 'react';
import {
    Page,
    Block,
} from 'framework7-react';
import {Helmet} from "react-helmet";

import IwdNavbar from '../shared/navbar/navbar';
import IwdBottomBar from '../shared/bottomBar/bottomBar';

export default class extends React.Component {
  render() {
    return (
  <Page>
    <Helmet>
        <title>Påmeldinger.no - 404</title>
        <meta name="description" content="Denne siden finnes ikke på Påmeldinger.no."/>
        <meta property="og:title" content="Påmeldinger.no - 404"/>
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/img/favicons/android-icon-192x192.png`}/>
        <meta name="description" content="Denne siden finnes ikke på Påmeldinger.no."/>
        <meta property="og:type" content="website"/>
    </Helmet>
    <IwdNavbar/>
    <div style={{width:'100%',maxWidth:'400px',marginLeft:'auto',marginRight:'auto'}}>
      <br/>
      <h2 large className="text-align-center">Oisann! Vi finner ikke siden du leter etter...</h2>
      <Block className="text-align-center">
        <p>Sikker på at du har skrevet riktig adresse? Eller har vi rotet med lenkene? Prøv på nytt, eller start forfra på <a className="external" href="https://pameldinger.no">https://pameldinger.no</a></p>
      </Block>
      <br/>
    </div>
    <IwdBottomBar/>
  </Page>);
  }
};