import * as React from 'react';
import {
    Navbar,
    NavLeft,
    NavRight,
    Button,
    Icon
} from 'framework7-react';
import './navbar.css'

export default class MainNavbar extends React.Component {

  
    render() {
      return <Navbar>
          <a href="/" style={{marginLeft:'0'}}>
            <NavLeft>
                <i className="logo-iwd"></i>
            </NavLeft>
          </a>
            <NavRight>
              <a href="/funksjoner" className="hideMobileScreen c-link">Funksjoner</a>
              <a href="/kontakt" className="hideMobileScreen c-link">Kontakt oss</a>
              <a href="/priser" className="hideMobileScreen c-link">Priser</a>
              <a href="/hjelp" className="hideMobileScreen c-link">Hjelp</a>
              <Button href="https://admin.pameldinger.no" className="hideMobileScreen" round external outline color="white" style={{paddingLeft:'6px',paddingRight:'12px',marginRight:0,marginLeft:"14px"}}><Icon style={{marginRight:'2px'}} material="account_circle"></Icon> Logg inn</Button>
              <Button href="/kom-i-gang" round fill style={{backgroundColor:'#FF0081',paddingLeft:'12px',paddingRight:'12px'}}>Prøv gratis</Button>
              <Button panelOpen="right" round color="white" large className="NavbarIconView hideFullScreen" iconMaterial="menu" style={{marginRight:"5px",marginLeft:"4px"}}/>
            </NavRight>
        </Navbar>;
    }
};