import HomePage from './components/pages/HomePage/main';
import PricingPage from './components/pages/PricingPage/main';
import GetStartedPage from './components/pages/GetStartedPage/main';
import ContactPage from './components/pages/ContactPage/main';
import HelpPage from './components/pages/help/main';
import FunctionPage from './components/pages/functionsPage/main';
import CookiesPage from './components/pages/CookiesPage';
import EmailPage from './components/pages/EmailPage';
import PrivacyInfoPage from './components/pages/PrivacyInfoPage';
import PaymentInfoPage from './components/pages/PaymentInfoPage/main';

import TermsPage from './components/pages/terms/main';
import NotFoundPage from './components/pages/NotFoundPage';


import ReactGA from 'react-ga';
ReactGA.initialize('UA-159128432-1');



export default [
  {
    path: '/',
    component: HomePage,
    on: {
      pageInit: function () {
        ReactGA.pageview('/');
      }
    }
  },
  {
    path: '/priser',
    component: PricingPage,
    on: {
      pageInit: function () {
        ReactGA.pageview('/priser');
      }
    }
  },
  {
    path: '/kom-i-gang',
    component: GetStartedPage,
    on: {
      pageInit: function () {
        ReactGA.pageview('/kom-i-gang');
      }
    }
  },
  {
    path: '/kontakt',
    component: ContactPage,
    on: {
      pageInit: function () {
        ReactGA.pageview('/kontakt');
      }
    }
  },
  {
    path: '/hjelp',
    component: HelpPage,
    on: {
      pageInit: function () {
        ReactGA.pageview('/help');
      }
    }
  },
  {
    path: '/funksjoner',
    component: FunctionPage,
    on: {
      pageInit: function () {
        ReactGA.pageview('/funksjoner');
      }
    }
  },
  {
    path: ['/stripe','/ta-betalt'],
    component: PaymentInfoPage,
    on: {
      pageInit: function () {
        ReactGA.pageview('/ta-betalt');
      }
    }
  },
  {
    path: '/cookies',
    component: CookiesPage,
    on: {
      pageInit: function () {
        ReactGA.pageview('/cookies');
      }
    }
  },
  {
    path: '/epost',
    component: EmailPage,
    on: {
      pageInit: function () {
        ReactGA.pageview('/epost');
      }
    }
  },
  {
    path: '/ditt-personvern',
    component: PrivacyInfoPage,
    on: {
      pageInit: function () {
        ReactGA.pageview('/epost');
      }
    }
  },
  {
    path: ['/personvern','/vilkar-for-deltakere','/vilkar-for-arrangor','/vilkar-for-brukere','/databehandleravtale'],
    component: TermsPage
  },
  {
    path: '(.*)',
    component: NotFoundPage,
    on: {
      pageInit: function () {
        ReactGA.pageview('/404');
      }
    }
  },
];
