import * as React from 'react';
import {
    Row,
    Col,
    BlockTitle,
    Block,
    Card,
    CardContent,
    Icon,
    List,
    ListItem,
    Link,
} from 'framework7-react';

import PageBlock from '../../../shared/pageBlock/pageBlock';

export default class PricingInfoBlock extends React.Component {

    render() {
      return <PageBlock width="660px">
        <Row>
            <Col width="100" medium="50">
                <BlockTitle large>
                    Priser
                </BlockTitle>
                <Block>
                    <b>
                    Vår prismodell er enkel og transparent. Du betaler kun for bruk! Vi har ingen skjulte kostnader!
                    </b>
                    <p>
                    Påmeldinger til gratisarrangementer <b>er gratis</b>. Påmeldinger som krever betaling koster vanligvis <b>4 kr + 3,5%</b> av beløpet.<sup style={{fontSize:'10px',opacity:'.7'}}>[1]</sup>
                    </p>
                </Block>
            </Col>
            <Col width="100" medium="50" className="hideMobileScreen">
                <div style={{height:'66px'}}></div>
                <Card outline style={{backgroundColor:'var(--f7-theme-color)'}}>
                    <CardContent className="text-align-center">
                        <Icon style={{color:"#fff",marginTop:'12px',opacity:'0.8'}} size={52} f7="creditcard"></Icon>
                        <p style={{color:'#fff',fontWeight:500,opacity:'0.9',fontSize:'18px',marginTop:'10px'}}>Betaling med kort</p>
                        <p style={{color:'#fff',fontWeight:500,opacity:'0.98',fontSize:'24px',marginTop:'-16px'}}>4 kr + 3,5%<sup style={{fontSize:'16px',opacity:'.7'}}>[1]</sup></p>
                    </CardContent>
                </Card>
            </Col>
        </Row>

        <BlockTitle style={{marginTop:'10px'}}>Priser for påmeldinger til gratisarrangementer</BlockTitle>
        <Card outline style={{backgroundColor:'#dfe4ea'}}>
        <List >
            <ListItem title="Påmelding til gratisarrangementer" after={<b style={{color:"#27ae60"}}>Gratis</b>}/>
        </List>
        </Card>
        <BlockTitle>Priser for påmeldinger til betaltearrangementer</BlockTitle>
        <Card outline style={{backgroundColor:'#dfe4ea'}}>
        <List >
            <ListItem title="Påmeldingsavgift til Påmeldinger.no" footer={<>Minimumspris er 6 kr<sup style={{fontSize:'10px',opacity:'.8'}}>[4]</sup></>} after={<>2 kr + 1,1 %<sup style={{fontSize:'10px',opacity:'.7'}}>[2]</sup><sup style={{fontSize:'10px',opacity:'.7'}}>[3]</sup></>}/>
        </List>
        </Card>
        <BlockTitle>Priser for valgfri tilleggstjenester</BlockTitle>
        <Card outline style={{backgroundColor:'#dfe4ea'}}>
        <List >
            <ListItem title="Epost til deltakere" footer="Valgfri" after={<><b style={{color:"#27ae60"}}>Gratis</b><sup style={{fontSize:'10px',opacity:'.7'}}>[2]</sup><sup style={{fontSize:'10px',opacity:'.7'}}>[3]</sup></>}/>
            <ListItem title="SMS til deltakere" footer="Valgfri" after={<>Kr 1,18 pr. SMS<sup style={{fontSize:'10px',opacity:'.7'}}>[2]</sup><sup style={{fontSize:'10px',opacity:'.7'}}>[3]</sup></>}/>
        </List>
        </Card>
        <Block style={{opacity:0.7}}>
            <p>
            1: Denne satsen er et veiledende eksempel. Det er den til enhver tid oppgitte prisen til din organisasjon og transaksjonsavgiften fra betalingsleverandøren som bestemmer endelig pris for påmeldinger.
            </p>
            <p>
            Påmeldinger.no samarbeider med Stripe (<Link target={'_blank'} external href={'https://stripe.com' }>https://stripe.com</Link>) for å levere raske og sikre betalinger. For at din organisasjon skal ta betalt fra deres deltakere, må dere opprette en egen avtale med Stripe, og følgelig betale en egen transaksjonsavgift til Stripe.
            </p>
            <p>
            For de fleste organisasjoner er transaksjonsgebyret fra Stripe 2,00 kr + 2,4 % av totalbeløpet (<Link target={'_blank'} external href={'https://stripe.com/en-no/pricing' }>https://stripe.com/en-no/pricing</Link>), og påmeldingsgebyret fra Påmeldinger.no 2,00 kr + 1,1 % av totalbeløpet.<sup style={{fontSize:'10px',opacity:'.7'}}>[2]</sup><sup style={{fontSize:'10px',opacity:'.7'}}>[3]</sup><sup style={{fontSize:'10px',opacity:'.7'}}>[4]</sup> <b>Totalgebyret for påmeldinger blir da 4,00 kr + 3,5 %.</b>
            </p>
            <p>
            2: Prisen oppgitt er veiledende. Det er den til enhver tid oppgitte prisen til din organisasjon som er avgjørende.
            </p>
            <p>
            3: Alle priser til Påmeldinger.no er oppgitt eksklusivt merverdiavgift (ekskl. mva.)
            </p>
            <p>
            4: Minsteprisen for påmeldingsgebyret til Påmeldinger.no er 6 kr.<sup style={{fontSize:'10px',opacity:'.7'}}>[2]</sup><sup style={{fontSize:'10px',opacity:'.7'}}>[3]</sup> Dette betyr at dersom summen av 2,00 kr + 1,2 % av totalbeløpet er mindre enn 6 kr, blir påmeldingsgebyret til Påmeldinger.no rundet opp til 6 kr.
            </p>
            <p>
            Alle beløp er oppgitt i NOK.
            </p>
        </Block>
      </PageBlock>
    }
};