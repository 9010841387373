import React from "react";
import { App, Panel, View, List, BlockTitle, ListItem, Block, Button, Icon } from "framework7-react";

import routes from "../routes";

export default class extends React.Component {
  render() {
    return (
      <App theme="md" id="no.pameldinger.admin" routes={routes}>
        <Panel right style={{ backgroundColor: "#EFF4F9" }}>
          <Block style={{ backgroundColor: "var(--f7-bars-bg-color)", height: "110px", marginTop: 0, paddingTop: "32px" }}>
            <BlockTitle large style={{ float: "left", color: "#ffffff" }}>
              Meny
            </BlockTitle>
            <Button panelClose style={{ width: "48px", float: "right" }} raised outline large iconMaterial="close" round color="white"></Button>
          </Block>
          <Block>
            <Button href="https://admin.pameldinger.no" round external large fill style={{ paddingLeft: "12px", paddingRight: "12px" }}>
              <Icon style={{ marginRight: "2px" }} material="account_circle"></Icon> Logg inn
            </Button>
          </Block>
          <List>
            {/*
            <ListItem panelClose title="Eksempler" link="/eksempler"></ListItem>
            <ListItem panelClose title="Referanser" link="/referanser"></ListItem>
            */}
            <ListItem panelClose title="Funksjoner" link="/funksjoner"></ListItem>
            <ListItem panelClose title="Kontakt oss" link="/kontakt"></ListItem>
            <ListItem panelClose title="Priser" link="/priser"></ListItem>
            <ListItem panelClose title="Hjelp" link="/hjelp"></ListItem>
          </List>
        </Panel>
        <View browserHistory browserHistorySeparator="" id="main-view" url="/" main className="safe-areas" animate={false} />
      </App>
    );
  }
}
