import React, { Suspense, lazy } from "react";
import { Page, BlockTitle, Block, Card, CardContent, Preloader } from "framework7-react";
import { Helmet } from "react-helmet";

import IwdNavbar from "../../shared/navbar/navbar";
import IwdBottomBar from "../../shared/bottomBar/bottomBar";

import PageBlock from "../../shared/pageBlock/pageBlock";
import Separator from "../../shared/separator/separator";

const ContactForm = React.lazy(() => import("../../shared/contactForm/contactForm"));

export default class extends React.Component {
  render() {
    return (
      <Page>
        <Helmet>
          <title>Påmeldinger.no - Ta kontakt</title>
          <meta name="description" content="Vi ønsker å hjelpe deg. Ta kontakt med oss i Påmeldinger.no dersom du har spørsmål, eller det er noe annet du lurer på!" />
          <meta property="og:title" content="Påmeldinger.no - Ta kontakt" />
          <meta property="og:image" content={`${process.env.PUBLIC_URL}/img/favicons/android-icon-192x192.png`} />
          <meta property="og:description" ccontent="Vi ønsker å hjelpe deg. Ta kontakt med oss i Påmeldinger.no dersom du har spørsmål, eller det er noe annet du lurer på!" />
          <meta property="og:type" content="website" />
        </Helmet>
        <IwdNavbar />
        <PageBlock width="660px">
          <BlockTitle large>Kontakt oss</BlockTitle>
          <Block>
            <p>Vi ønsker å hjelpe deg. Ta kontakt dersom du har spørsmål, eller det er noe annet du lurer på!</p>
            <p>
              Mange spørsmål og problemer kan du selv løse ved å se på vår <b>hjelp</b> side. På hjelp-siden finner du guider og instruksjoner, på alt fra opprettelse av et arrangement, til hjelp med å sende SMS til dine deltakere.
            </p>
            <a style={{ textDecoration: "underline" }} href="/hjelp">
              Trykk <b>her</b> for å gå til hjelp siden.
            </a>
          </Block>
          <Card outline style={{ backgroundColor: "var(--f7-theme-color)", color: "#fff" }}>
            <CardContent>
              <b>Er du allerede bruker?</b>
              <p>Dersom du allerede bruker Påmeldinger.no, bør du ta kontakt direkte med din kontaktperson. Kontaktpersonen er tilgjengelig utenfor vanlig åpningstid, og skal kontaktes dersom det oppstår feil eller spørsmål som ikke kan vente til neste arbeidsdag.</p>
              <p>
                Du finner din kontaktperson ved å <b>logge inn</b> og gå til <b>hjelp-siden</b>.{" "}
                <a href="https://admin.pameldinger.no/help" target="_blank" className="external" style={{ color: "#fff", textDecoration: "underline" }}>
                  Trykk <b>her</b> for å finne din kontaktperson.
                </a>
              </p>
            </CardContent>
          </Card>
          <BlockTitle medium>E-post</BlockTitle>
          <Block>
            <p>
              Har du et spørsmål? Ta kontakt på e-post til: <b>post@pameldinger.no</b>
            </p>
            <p>
              Spørsmål om faktura? Ta kontakt på e-post til: <b>faktura@pameldinger.no</b>
            </p>
            <p>
              <b>Ikke arrangør?</b> Dersom du har spørsmål om et arrangement, må du ta kontakt direkte med arrangøren av arrangementet.
            </p>
          </Block>
          <Separator text="eller" />
          <Suspense
            fallback={
              <div className="text-align-center" style={{ margin: "26px" }}>
                <Preloader></Preloader>
              </div>
            }
          >
            <ContactForm />
          </Suspense>
        </PageBlock>
        <IwdBottomBar />
      </Page>
    );
  }
}
