import React from 'react';
import {
    Page,
} from 'framework7-react';
import {Helmet} from "react-helmet";

import IwdNavbar from '../../shared/navbar/navbar';
import IwdBottomBar from '../../shared/bottomBar/bottomBar';

import PricingInfoBlock from './pricingInfoBlock/pricingInfoBlock';

export default class extends React.Component {
    render() {
        return <Page>
            <Helmet>
                <title>Påmeldinger.no - Priser</title>
                <meta name="description" content="Vår prismodell er enkel og transparent. Du betaler kun for bruk! Vi har ingen skjulte kostnader!"/>
                <meta property="og:title" content="Påmeldinger.no - Priser"/>
                <meta property="og:image" content={`${process.env.PUBLIC_URL}/img/favicons/android-icon-192x192.png`}/>
                <meta property="og:description" content="Vår prismodell er enkel og transparent. Du betaler kun for bruk! Vi har ingen skjulte kostnader!"/>
                <meta property="og:type" content="website"/>
            </Helmet>
            <IwdNavbar/>
            <PricingInfoBlock/>
            <IwdBottomBar/>
        </Page>
    }
};
