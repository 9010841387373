import React, { Suspense, lazy } from 'react';
import {
    Page,
    BlockTitle,
    Block,
    Link,
    Preloader
} from 'framework7-react';
import {Helmet} from "react-helmet";

import './helpPage.css';

import IwdNavbar from '../../shared/navbar/navbar';
import IwdBottomBar from '../../shared/bottomBar/bottomBar';

import PageBlock from '../../shared/pageBlock/pageBlock';
import {HelpVideosAll} from './helpVideos/helpVideos';

const HelpAccordionList = React.lazy(() => import('./helpAccordionList'));

export default class extends React.Component {
    render() {
        return <Page className="help-page">
            <Helmet>
                <title>Påmeldinger.no - Hjelp</title>
                <meta name="description" content="På denne siden finner du svar på de vanligste spørsmålene brukerne våre lurer på om Påmeldinger.no."/>
                <meta property="og:title" content="Påmeldinger.no - Hjelp"/>
                <meta property="og:image" content={`${process.env.PUBLIC_URL}/img/favicons/android-icon-192x192.png`}/>
                <meta property="og:description" content="På denne siden finner du svar på de vanligste spørsmålene brukerne våre lurer på om Påmeldinger.no."/>
                <meta property="og:type" content="website"/>
            </Helmet>
            <IwdNavbar/>
            <PageBlock width="660px">
                <BlockTitle large>
                    Få hjelp
                </BlockTitle>
                <Block>
                    <p style={{fontSize:'20px'}}>
                    På denne siden finner du svar på de vanligste spørsmålene brukerne våre lurer på. <Link style={{textDecoration:'underline'}} href="/kontakt">Ta kontakt dersom du ikke finner svar på det du lurer på.</Link>
                    </p>
                </Block>
            </PageBlock>
            <PageBlock width="860px" color="#f0f1f5">
                <BlockTitle large>
                    Se våre hjelpevideoer
                </BlockTitle>
                <Block>
                    <p style={{fontSize:'20px'}}>
                    Vi har laget noen videoer som viser deg hvor enkelt det er å bruke Påmeldinger.no, og hva du må gjøre for å opprette et arrangement.
                    </p>
                </Block>
                <HelpVideosAll/>
            </PageBlock>
            <PageBlock width="860px">
                <BlockTitle large>
                    Ofte stilte spørsmål
                </BlockTitle>
                <Block>
                    <p style={{fontSize:'20px'}}>
                    Under finner du våre ofte stilte spørsmål. Her har vi samlet svar på det meste du kan tenke deg om hvordan du bruker Påmeldinger.no, og hva du må tenke på når du bruker oss. 
                    </p>
                </Block>
            </PageBlock>
            <Suspense fallback={<div className="text-align-center" style={{margin:'26px'}}><Preloader></Preloader></div>}>
                <HelpAccordionList/>
            </Suspense>
            <IwdBottomBar/>
        </Page>
    }
};
