import * as React from 'react';
import {
    Row,
    Col,
    Card,
    CardContent,
    Icon
} from 'framework7-react';

import PageBlock from '../../../shared/pageBlock/pageBlock';
import './featuresBlock.css';

class FeatureCard  extends React.Component {
  render() {
    return <Card className="features_card">
      <CardContent>
        <Icon className="feature-icon" size={72} f7={this.props.icon}/>
        <h5>
          {this.props.title}
        </h5>
        <p>
          {this.props.children}
        </p>
      </CardContent>
    </Card>
  }
}

export default class FeaturesBlock extends React.Component {

    render() {
      return <div className="features_block">
      <PageBlock width="1000px">
        <h2 className="text-align-center">Våre funksjoner</h2>
        <Row noGap>
            <Col width="50" medium="33" large="25">
              <FeatureCard title="Tilpasset påmelding" icon="pencil_ellipsis_rectangle">
              Du velger selv hvilken opplysninger dine deltakere skal oppgi ved påmelding. Du kan velge mellom fritekst, multiple choice og checkboxes. Du kan for eksempel spørre om studieretning eller allergier.
              </FeatureCard>
            </Col>
            <Col width="50" medium="33" large="25">
              <FeatureCard title="Ta betalt fra dine deltakere" icon="creditcard">
              Du kan ta betalt fra deltakeren direkte gjennom påmeldingen.<br/><br/>Deltakeren betaler med <b>bankkort</b> eller <b>Vipps</b>, og du får raskt utbetalt pengene. <a href="/ta-betalt" className="c-text-link">Finn ut mer.</a>
              </FeatureCard>
            </Col>
            <Col width="50" medium="33" large="25">
              <FeatureCard title="Scan QR-code" icon="qrcode_viewfinder">
              Det skal være enkelt å sjekke om deltakeren er påmeldt. Når en deltaker melder seg på får han en QR-kode.<br/><br/>Du som arrangør kan ved hjelp av en smarttelefon skanne QR-koden og registrere deltakeren.
              </FeatureCard>
            </Col>
            <Col width="50" medium="33" large="25">
              <FeatureCard title="Full oversikt" icon="graph_square">
              Som arrangør har du full oversikt over arrangementet via ditt dashboard.<br/><br/>Her har du sanntidsoversikt over arrangementer, påmeldte, kjøp, utbetalinger og mye annet.
              </FeatureCard>
            </Col>
            <Col width="50" medium="33" large="25">
              <FeatureCard title="Varslinger på E-post og SMS" icon="envelope">
              Deltakerne dine får automatisk en e-post eller SMS når de melder seg på. Her finner de opplysninger om arrangementet og sin påmelding. Du kan også sende ut egne e-post eller SMS varslinger.
              </FeatureCard>
            </Col>
            <Col width="50" medium="33" large="25">
              <FeatureCard title="Løpende utbetalinger" icon="repeat">
              Alle salg fra dine arrangementer utbetales løpende 2 eller 7 dager etter at salget ble gjennomført.
              <br/>
              <br/>
              <a href="/ta-betalt" className="c-text-link">
              Finn ut mer om salg og utbetalinger.
              </a>
              </FeatureCard>
            </Col>
            <Col width="50" medium="33" large="25">
              <FeatureCard title="Venteliste" icon="person_2">
              På arrangementer med begrenset antall plasser, kan dere ha ventelister. Deltakere blir automatisk satt på venteliste dersom arrangementet er fullt. Deltakeren kan følge sin plass i køen og blir varslet dersom han får plass på arrangement.
              </FeatureCard>
            </Col>
            <Col width="50" medium="66" large="25">
              <Card className="features_card" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <CardContent className="text-align-center" style={{marginTop:'-20px'}}>
                  <p style={{fontSize:'30px'}}>
                    + 55 andre
                  </p>
                  <a href="/funksjoner" target="_blank" className="link c-text-link">
                    Se alle funksjoner
                  </a>
                </CardContent>
              </Card>
            </Col>
        </Row>
      </PageBlock>
      <br/>
      <br/>
    </div>
    }
};